<template>
  <div class="nav-item">
    <router-link
      :class="['nav-link text-active-primary ms-0 me-lg-8 py-4', { active: isActive }]"
      :to="typeof to === 'object' ? to : { name: to }"
    >
      {{ title }}
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    to: {
      type: [String, Object],
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
