<template>
  <div :class="['position-relative rounded ratio overflow-hidden', `ratio-${ratio}`]">
    <googlemaps-map
      v-if="!isLoading"
      class="position-absolute"
      :center.sync="dCenter"
      :zoom="16"
      :options="{
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        overviewMapControl: false,
        rotateControl: false,
        draggable: false,
        scrollwheel: false,
        disableDoubleClickZoom: true,
        controlSize: 20,
      }"
    >
      <googlemaps-marker :position.sync="dCenter" />
    </googlemaps-map>
    <px-loader v-if="isLoading" :is-full-window="false" bg="blue" />
    <div
      v-if="!dCenter"
      class="position-absolute top-0 bottom-0 start-0 end-0 z-index-2 d-flex align-items-center justify-content-center bg-light-info rounded border border-info p-4"
    >
      <span class="fs-8 text-info">No Map Available</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    ratio: {
      type: String,
      default: '1x1',
    },
    center: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isLoading: true,
      dCenter: null,
    };
  },
  watch: {
    center(newCenter) {
      if (newCenter) {
        this.isLoading = false;
        this.dCenter = this.center;
      }
    },
  },
  mounted() {
    this.dCenter = this.center;
  },
};
</script>
