<template>
  <div>
    <px-toolbar :title="details ? details.attributes.address : ''" :breadcrumbs="breadcrumbs">
      <template #right>
        <router-link
          :to="{ name: 'projects-single-archive', params: { id: projectId } }"
          class="btn btn-flex btn-sm btn-light"
        >
          <i class="bi bi-archive me-1" />
          Archive
        </router-link>
      </template>
    </px-toolbar>

    <div class="container-xxl pb-5 pb-xxl-8">
      <div class="content flex-row-fluid">
        <div class="row mb-5 mb-xxl-8">
          <div class="col-md-8 mb-4 mb-md-0">
            <div class="card h-100">
              <div class="card-body pt-9 pb-0 d-flex flex-column">
                <div class="d-flex flex-wrap flex-sm-nowrap mb-7">
                  <div class="me-7">
                    <div class="w-200px">
                      <px-google-map
                        :center="
                          details && details.attributes
                            ? { lat: details.attributes.lat, lng: details.attributes.lon }
                            : null
                        "
                      />
                    </div>
                  </div>
                  <div class="flex-grow-1 d-flex flex-column">
                    <div
                      v-if="details && details.attributes"
                      class="d-md-flex justify-content-between align-items-start flex-wrap mb-6"
                    >
                      <div class="position-relative pe-10 mb-6 mb-xl-0">
                        <px-list-has-icon-item
                          v-for="(aboutItem, index) in contacts"
                          :key="aboutItem.id"
                          :extended-class="[{ 'mt-2': index !== 0 }]"
                          :title="aboutItem.title"
                          :svg-icon="aboutItem.icon"
                        />
                      </div>

                      <div class="d-flex align-items-center ms-2">
                        <span class="badge badge-light-primary fw-medium text-uppercase text-ls fs-10 px-3 py-2">
                          {{ details.attributes.status || 'Lead' }}
                        </span>
                        <px-dropdown
                          class="ms-4"
                          :items="[
                            { title: 'Edit project', action: openProjectModal },
                            { title: 'Share project', action: openShareModal },
                          ]"
                        />
                      </div>
                    </div>

                    <div class="d-xl-flex justify-content-between align-items-start flex-wrap mt-auto">
                      <small class="d-block text-muted mt-auto mb-6 mb-xl-0">
                        Created:
                        <strong v-if="activityDates.created.length">
                          {{ activityDates.created | moment('DD.MM.YYYY') }}
                        </strong>
                        | Last Updated:
                        <strong v-if="activityDates.updated.length">
                          {{ activityDates.updated | moment('DD.MM.YYYY') }}
                        </strong>
                      </small>
                    </div>
                  </div>
                </div>

                <div v-if="tabs" class="border-top mt-auto">
                  <px-nav-tab :tabs="tabs" :current="currentTab" />
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="card h-100">
              <div class="card-body pt-9">
                <div v-if="details && details.attributes" class="d-flex mb-4">
                  <div class="me-8">
                    <div class="fs-3 fw-bold">
                      {{ $n(details.attributes.balance.sub_total, 'currency', 'en-CA') }}
                    </div>
                    <div class="text-uppercase text-ls text-muted fs-9 mb-4">Budget</div>
                  </div>
                  <div class="text-muted me-4">
                    <div class="fs-3 fw-bold">
                      {{ $n(details.attributes.balance.outstanding, 'currency', 'en-CA') }}
                    </div>
                    <div class="text-uppercase text-ls text-muted fs-9 mb-4">Outstanding with HST</div>
                  </div>
                </div>

                <px-progress-with-text
                  v-for="(item, index) in statistics"
                  :key="item.id"
                  :extended-class="[{ 'mt-2': index !== 0 }]"
                  :progress-bg-class="item.bg"
                  :value="$n(item.sum, 'currency', 'en-CA')"
                  :title="item.title"
                  :side-text="item.qty"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="position-relative min-h-200px">
          <px-loader v-if="!details" :is-full-window="false" bg="blue" />
          <router-view v-if="details" :item="details" />
        </div>
      </div>
    </div>

    <px-new-modal :is-visible="isProjectModalOpened" :close-modal="closeProjectModal">
      <template #title>
        <h3>New Project</h3>
      </template>
      <template #content>
        <pxm-project :item="details" @onClose="closeProjectModal" />
      </template>
    </px-new-modal>

    <px-new-modal modal-size="mw-650px" :is-visible="isShareModalOpened" :close-modal="closeShareModal">
      <template #title>
        <h3>Share</h3>
      </template>
      <template #content>
        <pxm-share-compact
          :item-id="projectId"
          :code="details.attributes.code"
          :secret="details.attributes.secret"
          @onClose="closeShareModal"
        />
      </template>
    </px-new-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import PxToolbar from '@/layouts/sections/PxToolbar';

import PxNavTab from '@/components/elements/PxNavTab';
import PxGoogleMap from '@/components/elements/PxGoogleMap';

import PxmProject from '@/modals/PxmProject';
import PxmShareCompact from '@/modals/PxmShareCompact';

export default {
  components: { PxToolbar, PxNavTab, PxGoogleMap, PxmProject, PxmShareCompact },
  data() {
    return {
      isProjectModalOpened: false,
      isShareModalOpened: false,
    };
  },
  computed: {
    ...mapGetters({
      breadcrumbs: 'projectsSingle/breadcrumbs',
      contacts: 'projectsSingle/contacts',
      statistics: 'projectsSingle/statistics',
      tabs: 'projectsSingle/tabs',
      details: 'projectsSingle/details',
    }),
    projectId() {
      return this.$route.params.id;
    },
    currentTab() {
      return this.$route.name;
    },
    activityDates() {
      return {
        created: this.details?.attributes?.created_at || '',
        updated: this.details?.attributes?.updated_at || '',
      };
    },
  },
  methods: {
    ...mapActions({
      actionDetails: 'projectsSingle/details',
    }),
    openProjectModal() {
      this.isProjectModalOpened = true;
    },
    closeProjectModal() {
      this.isProjectModalOpened = false;
    },
    openShareModal() {
      this.isShareModalOpened = true;
    },
    closeShareModal() {
      this.isShareModalOpened = false;
    },
  },
  async mounted() {
    await this.actionDetails({
      id: this.projectId,
      params: {
        fields:
          'status,balance,code,secret,archive,address,statistics,first_name,last_name,phone,email,created_at,updated_at,lat,lon',
      },
    });
  },
};
</script>
