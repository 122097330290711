<template>
  <div class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fw-medium">
    <px-nav-tab-item
      v-for="(item, index) in tabs"
      :key="item.id || index"
      :title="item.title"
      :to="item.to"
      :is-active="item.to === current"
    />
  </div>
</template>

<script>
import PxNavTabItem from '@/components/elements/PxNavTabItem';

export default {
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    current: {
      type: String,
      required: true,
    },
  },
  components: { PxNavTabItem },
};
</script>
