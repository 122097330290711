<template>
  <div :class="['toolbar', padding]">
    <div class="container-xxl d-flex flex-stack flex-wrap">
      <div class="page-title d-flex flex-column me-3">
        <h1 class="text-white">{{ title }}</h1>
        <px-new-breadcrumbs v-if="breadcrumbs && breadcrumbs.length" :breadcrumbs="breadcrumbs" />
      </div>

      <div class="align-self-start">
        <slot name="right" />
      </div>
    </div>
  </div>
</template>

<script>
import PxNewBreadcrumbs from '@/components/elements/PxNewBreadcrumbs';

export default {
  props: {
    padding: {
      type: String,
      default: 'py-5 py-lg-15',
    },
    title: {
      type: String,
      default: '',
    },
    breadcrumbs: {
      type: [Array, null],
      default: null,
    },
  },
  components: { PxNewBreadcrumbs },
};
</script>
