var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['position-relative rounded ratio overflow-hidden', ("ratio-" + _vm.ratio)]},[(!_vm.isLoading)?_c('googlemaps-map',{staticClass:"position-absolute",attrs:{"center":_vm.dCenter,"zoom":16,"options":{
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      overviewMapControl: false,
      rotateControl: false,
      draggable: false,
      scrollwheel: false,
      disableDoubleClickZoom: true,
      controlSize: 20,
    }},on:{"update:center":function($event){_vm.dCenter=$event}}},[_c('googlemaps-marker',{attrs:{"position":_vm.dCenter},on:{"update:position":function($event){_vm.dCenter=$event}}})],1):_vm._e(),(_vm.isLoading)?_c('px-loader',{attrs:{"is-full-window":false,"bg":"blue"}}):_vm._e(),(!_vm.dCenter)?_c('div',{staticClass:"position-absolute top-0 bottom-0 start-0 end-0 z-index-2 d-flex align-items-center justify-content-center bg-light-info rounded border border-info p-4"},[_c('span',{staticClass:"fs-8 text-info"},[_vm._v("No Map Available")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }